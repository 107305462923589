.App {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: black;
}
p {
  font-family: Regular;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: "#E0D9FA";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.gradiant {
  background: linear-gradient(to right, #f5d1f5, #e0d9fa, #c2f7f2);
}

.red {
  color: red;
}

.termpage {
  background-color: #f7f7f7;
  padding: 20px 100px;
}
.terms {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
span {
  text-align: justify;
  line-height: 24px;
}
.text {
  font-family: "DM Serif Display";
  font-size: 48px;
  font-weight: 800;
}
.dtext {
  font-family: "DM Serif Display";
  font-size: 16px;
  font-weight: 600;
}
/* .popdoc {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  padding: 3%;
  padding-bottom: 0;
}
.popdata {
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  background-color: white;
} */
.popdoc {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px; /* Adjust padding as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a subtle box shadow for depth */
}

.popdata {
  position: relative; /* Make it relative to the popdoc container */
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px; /* Adjust padding as needed */
  border-radius: 8px; /* Add rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow for depth */
}

.documents {
  margin-bottom: 10px; /* Add some space between document items */
}

.document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #dee2e6; /* Border color */
  border-radius: 5px; /* Add rounded corners */
}

.document-item span {
  flex: 1; /* Make the filename span take the available space */
}

.btn {
  cursor: pointer; /* Change cursor to pointer for buttons */
}

.btn-success {
  background-color: #28a745; /* Green color for success button */
  color: white;
}

.btn-danger {
  background-color: #dc3545; /* Red color for danger button */
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "black";
  src: local("Gramatika-Black"),
    url("./fonts/Gramatika-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";
  src: local("Gramatika-Regular"),
    url("./fonts/Gramatika-Regular.ttf") format("truetype");
}
