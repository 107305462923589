.accountheader {
  margin-top: 5%;
  padding-left: 3.8%;
}
@media (max-width: 1045px) {
  .accountheader {
    margin-top: 9%;
  }
}
@media (max-width: 860px) {
  .accountheader {
    margin-top: 11%;
  }
}
@media (max-width: 690px) {
  .accountheader {
    margin-top: 13%;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 8px;
  }

  .accountheader::-webkit-scrollbar {
    width: 0.3em;
  }

  .accountheader::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
@media screen and (max-width: 585px) {
  .accountheader {
    margin-top: 16%;
  }
  /* .newNavlocate {
    display: none;
  } */
}

@media screen and (min-width: 980px) {
  .navbar_container {
    position: relative;
  }
  .navbar_logo {
    position: absolute;
    left: 50%;
    margin-left: -80px;
    display: block;
  }
  .Address_mapping {
    height: 400px;
  }
}
.brandlogos {
  height: 30px;
}
@media screen and (max-width: 585px) {
  .brandlogos {
    width: 140px;
    height: 20px;
  }
}

.itemproduct .unselected-button {
  border-radius: 20px;
  border: none;
  background-color: #e0d9fa;
  padding: 8px 18px 8px 18px;
  min-width: max-content;
}

.itemproduct .selected-button {
  border-radius: 20px;
  border: none;
  background-color: black;
  padding: 8px 18px 8px 18px;
  color: white;
  min-width: max-content;
}

.itemproduct::-webkit-scrollbar {
  width: 0.3em;
}

.itemproduct::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* ----------------------------------HEAD SECTION----------------------------- */
.headsection {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #f7f7f7;
  padding: 5% 5% 10% 5%;
}
.head_text {
  margin-top: 7%;
  width: 40%;
}
.free_shooping {
  font-size: 1.2vw;
}
.head_text .head_heading2 {
  font-size: 3.8vw;
  width: 92%;
}
.head_text .paragraph {
  font-size: 1.4vw;
}
.head_text .head_button {
  border-radius: 20px;
  border: none;
  background-color: #e0d9fa;
  padding: 8px 14px 8px 14px;
  /* font-weight: bold; */
  font-family: black;
}
.head_image {
  width: 40%;
}
.head_image .head_image1 {
  position: absolute;
  top: 39%;
  right: 18%;
  width: 30vw;
  height: 30vw;
}
.head_image .head_image2 {
  position: absolute;
  right: 15%;
  top: 38%;
  width: 35vw;
  height: 40vw;
}
@media (max-width: 688px) {
  .headsection {
    flex-direction: column;
    height: 300px;
  }
  .head_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0%;
    width: 100%;
  }
  .free_shooping {
    font-size: 2vw;
  }
  .head_text .head_heading2 {
    font-size: 4vw;
  }
  .head_text .paragraph {
    font-size: 2.5vw;
  }
  .head_image .head_image1 {
    top: 65vh;
    right: 35%;
    width: 40vw;
    height: 40vw;
  }
  .head_image .head_image2 {
    top: 64vh;
    right: 33%;
    width: 45vw;
    height: 50vw;
  }
}
@media (max-width: 400px) {
  .headsection {
    flex-direction: column;
    height: 450px;
  }
  .head_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0%;
    width: 100%;
  }
  .free_shooping {
    padding-top: 5%;
    font-size: 4vw;
  }
  .head_text .head_heading2 {
    font-size: 8vw;
  }
  .head_text .paragraph {
    font-size: 4vw;
  }
  .head_image .head_image1 {
    top: 70vh;
    right: 15%;
    width: 80vw;
    height: 80vw;
  }
  .head_image .head_image2 {
    top: 69vh;
    right: 13%;
    width: 85vw;
    height: 90vw;
  }
}
@media (min-width: 1541px) {
  .head_image .head_image1 {
    top: 26%;
  }
  .head_image .head_image2 {
    top: 20%;
  }
}

/* -------------------------Groaming Section----------------------------- */
.card_section1 {
  padding: 5% 5% 0% 5%;
  display: flex;
  justify-content: space-between;
}
/* .Grooming {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Grooming.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
} */
.Grooming {
  width: calc(33.33% - 20px);
  height: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Grooming.png");
  background-size: auto 70%, contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #b5f9f1;
  /* color: white; */
}

.Grooming_heading {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 8%;
}
.Grooming_paragraph {
  width: 55%;
  margin-left: 10%;
  margin-top: 2%;
  font-size: 1.2vw;
}
.Grooming_button {
  border-radius: 20px;
  padding: 5px 10px;
  border: none;
  /* background-color: #e0d9fa; */
  background-color: black;
  color: white;
  margin-left: 10%;
  font-weight: bold;
  margin-top: 2%;
}
/* .Veterinary {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Veterinary.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
} */
.Veterinary {
  width: calc(33.33% - 20px);
  height: 100%; /* Set the height to 100% */
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Veterinary1.png");
  background-size: auto 70%, contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #e3d7fc;
  /* color: white; */
}
.Veterinary_heading {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 8%;
}
.Veterinary_paragraph {
  width: 65%;
  margin-left: 10%;
  margin-top: 2%;
  font-size: 1.2vw;
}
.Veterinary_button {
  border-radius: 20px;
  border: none;
  padding: 5px 10px;
  /* background-color: #e0d9fa; */
  background-color: black;
  color: white;
  margin-left: 10%;
  font-weight: bold;
  margin-top: 2%;
}
/* .Day_Care {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Day_Care.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
} */

.Day_Care {
  width: calc(33.33% - 20px);
  height: 100%; /* Set the height to 100% */
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Video_Call.png");
  background-size: auto 70%, contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #f5d1f5;
  /* color: white; */
}

.Day_Care_heading {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 8%;
}
.Day_Care_paragraph {
  width: 55%;
  margin-left: 10%;
  margin-top: 2%;
  font-size: 1.2vw;
}
.Day_Care_button {
  border-radius: 20px;
  border: none;
  padding: 5px 10px;
  /* background-color: #e0d9fa; */
  background-color: black;
  color: white;
  margin-left: 10%;
  font-weight: bold;
  margin-top: 2%;
}

/* -----------------50% OFF SECTION------------------------ */

.fifty_off {
  background-color: #c2f7f2;
  margin: 2% 5% 2% 5%;
  width: 90%;
  display: flex;
  height: 185px;
}
.div1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3% 5px 3% 5px;
  width: 25%;
}
.fifty_off .div1 h2 {
  font-size: 1.7vw;
  font-weight: 500;
}
.fifty_off .div1 h3 {
  font-size: 1vw;
  font-weight: 350;
}
.fifty_off .div2 .image1 {
  position: absolute;
}
.fifty_off .div2 .image2 {
  position: absolute;
}
.fifty_off .div3 {
  margin-left: 60%;
  margin-top: 6%;
}
.fifty_off .div3 button {
  border-radius: 20px;
  border: none;
  padding: 10px 14px;
  background-color: #e0d9fa;
  font-weight: bold;
}
@media screen and (max-width: 430px) {
  .fifty_off {
    height: 320px;
    display: flex;
    flex-direction: column;
  }
  .fifty_off .div1 {
    /* padding-top: 8%; */
    padding: 8% 24px 0 24px;
    width: 100%;
  }
  .fifty_off .div1 h2 {
    font-size: 3.6vw;
  }
  .fifty_off .div1 h3 {
    font-size: 3.6vw;
  }
  .fifty_off .div2 .image1 {
    width: 95vw;
    height: 150px;
    right: 4%;
    position: absolute;
  }
  .fifty_off .div2 .image2 {
    padding-top: 4%;
    right: 4%;
    width: 95vw;
    height: 150px;
    position: absolute;
  }
  .fifty_off .div3 {
    position: relative;
    top: 45%;
    margin-top: 7%;
    margin-left: 0;
    padding-left: 30%;
  }
}
@media screen and (min-width: 431px) and (max-width: 619px) {
  .fifty_off {
    height: 400px;
    display: flex;
    flex-direction: column;
  }
  .fifty_off .div1 {
    /* padding-top: 8%; */
    padding: 8% 24px 0 24px;
    width: 100%;
  }
  .fifty_off .div1 h2 {
    font-size: 3.5vw;
  }
  .fifty_off .div1 h3 {
    font-size: 3.5vw;
  }
  .fifty_off .div2 .image1 {
    width: 87vw;
    height: 180px;
    position: absolute;
  }
  .fifty_off .div2 .image2 {
    padding-top: 4%;
    width: 87vw;
    height: 180px;
    position: absolute;
  }
  .fifty_off .div3 {
    position: relative;
    top: 45%;
    right: 55%;
  }
}
@media screen and (min-width: 620px) and (max-width: 724px) {
  .fifty_off {
    height: 285px;
    display: flex;
    flex-direction: column;
  }
  .fifty_off .div2 .image1 {
    width: 62vw;
    height: 180px;
    position: relative;
    top: 5%;
    left: 15%;
  }
  .fifty_off .div2 .image2 {
    width: 62vw;
    height: 180px;
    position: relative;
    bottom: 35%;
    left: 15%;
  }
  .fifty_off .div3 {
    position: absolute;
    left: 15%;
  }
}
@media screen and (min-width: 725px) and (max-width: 1000px) {
  .fifty_off .div3 {
    margin-left: 55%;
  }
  .fifty_off .div2 .image1 {
    width: 42vw;
    height: 180px;
  }
  .fifty_off .div2 .image2 {
    width: 42vw;
    height: 180px;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1271px) {
  .fifty_off {
    height: 150px;
  }
  .fifty_off .div2 .image1 {
    width: 52vw;
  }
  .fifty_off .div2 .image2 {
    width: 52vw;
  }
}
@media (min-width: 1640px) {
  .fifty_off {
    width: 87%;
  }
  .div1 {
    padding-top: 2%;
  }
  .fifty_off .div2 {
    padding-left: 9%;
  }
  .fifty_off .div3 {
    margin-left: 56%;
    margin-top: 5%;
  }
}

@media (min-width: 2200px) {
  .fifty_off {
    width: 87%;
    margin-top: 5%;
  }
  .div1 {
    padding-top: 3%;
  }
  .fifty_off .div2 {
    padding-left: 16%;
  }
  .fifty_off .div3 {
    margin-left: 54%;
    margin-top: 4%;
  }
}

/* ---------------------VIDEO CALL SECTION----------------------- */
.card_section2 {
  padding: 5% 5% 0% 5%;
  display: flex;
  justify-content: space-between;
}
.Video_Call {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Video_Call.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* color: white; */
}

.Video_Call_heading {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 8%;
}
.Video_Call_paragraph {
  width: 45%;
  margin-left: 10%;
  margin-top: 2%;
  font-size: 1.2vw;
}
.Video_Call_button {
  border-radius: 20px;
  border: none;
  padding: 5px 10px;
  /* background-color: #e0d9fa; */
  background-color: black;
  color: white;
  margin-left: 10%;
  font-weight: bold;
  margin-top: 2%;
}
/* .Vet_Home {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Vet_Home.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
} */

.Vet_Home {
  width: calc(33.33% - 20px);
  height: 100%; /* Set the height to 100% */
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Training1.png");
  background-size: auto 70%, contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #d3e5ff;
  /* color: white; */
}
.Vet_Home_heading {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 8%;
}
.Vet_Home_paragraph {
  width: 45%;
  margin-left: 10%;
  margin-top: 2%;
  font-size: 1.2vw;
}
.Vet_Home_button {
  border-radius: 20px;
  border: none;
  padding: 5px 10px;
  /* background-color: #e0d9fa; */
  background-color: black;
  color: white;
  margin-left: 10%;
  font-weight: bold;
  margin-top: 2%;
}
.Training {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-image: url("../../images/Training.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
.Training_heading {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 8%;
}
.Training_paragraph {
  width: 45%;
  margin-left: 10%;
  margin-top: 2%;
  font-size: 1.2vw;
}
.Training_button {
  border-radius: 20px;
  border: none;
  padding: 5px 10px;
  /* background-color: #e0d9fa; */
  background-color: black;
  color: white;
  margin-left: 10%;
  font-weight: bold;
  margin-top: 2%;
}

@media (max-width: 968px) {
  .Grooming,
  .Veterinary,
  .Day_Care {
    background-size: auto 70%, contain;
    padding-bottom: 30px;
  }
  .Grooming_button,
  .Veterinary_button,
  .Day_Care_button {
    padding: 3px 7px;
    margin-top: 0.5%;
  }
}
/* @media (max-width: 824px) {
  .card_section1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card_section2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Grooming,
  .Veterinary,
  .Day_Care,
  .Video_Call,
  .Vet_Home,
  .Training {
    width: calc(66.33% - 20px);
    background-size: contain;
    padding-bottom: 30px;
  }
  .Grooming_paragraph,
  .Veterinary_paragraph,
  .Day_Care_paragraph,
  .Video_Call_paragraph,
  .Vet_Home_paragraph,
  .Training_paragraph {
    font-size: 2vw;
    width: 35%;
  }
  .Grooming_button,
  .Veterinary_button,
  .Day_Care_button,
  .Video_Call_button,
  .Vet_Home_button,
  .Training_button {
    padding: 3px 7px;
    margin-top: 0%;
  }
} */
/* @media (min-width: 624px){
  .Grooming_paragraph,
  .Veterinary_paragraph,
  .Day_Care_paragraph,
  .Video_Call_paragraph,
  .Vet_Home_paragraph,
  .Training_paragraph {
    font-size: 60px;
    width: 35%;
  }
} */

@media (max-width: 824px) {
  .card_section1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card_section2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Grooming,
  .Veterinary,
  .Day_Care,
  .Video_Call,
  .Vet_Home,
  .Training {
    width: 100%;
    background-size: auto 70%, contain;
    padding-bottom: 30px;
  }
  .Grooming_paragraph,
  .Veterinary_paragraph,
  .Day_Care_paragraph,
  .Video_Call_paragraph,
  .Vet_Home_paragraph,
  .Training_paragraph {
    font-size: 2vw;
    width: 35%;
  }
  .Grooming_button,
  .Veterinary_button,
  .Day_Care_button,
  .Video_Call_button,
  .Vet_Home_button,
  .Training_button {
    padding: 3px 7px;
    margin-top: 0%;
  }
}

@media (max-width: 600px) {
  .card_section1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card_section2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Grooming,
  .Veterinary,
  .Day_Care,
  .Video_Call,
  .Vet_Home,
  .Training {
    width: 100%;
    background-size: auto 70%, contain;
    padding-bottom: 30px;
  }
  .Grooming_paragraph,
  .Veterinary_paragraph,
  .Day_Care_paragraph,
  .Video_Call_paragraph,
  .Vet_Home_paragraph,
  .Training_paragraph {
    font-size: 3vw;
    width: 65%;
  }
  .Grooming_button,
  .Veterinary_button,
  .Day_Care_button,
  .Video_Call_button,
  .Vet_Home_button,
  .Training_button {
    padding: 3px 7px;
    margin-top: 0%;
  }
}

@media (min-width: 1901px) {
  .Grooming,
  .Veterinary,
  .Day_Care,
  .Video_Call,
  .Vet_Home,
  .Training {
    background-repeat: repeat;
    width: 26vw;
    height: 300px;
  }
  .Grooming_heading,
  .Veterinary_heading,
  .Day_Care_heading,
  .Video_Call_heading,
  .Vet_Home_heading,
  .Training_heading {
    font-size: 30px;
  }
  .Grooming_paragraph,
  .Veterinary_paragraph,
  .Day_Care_paragraph,
  .Video_Call_paragraph,
  .Vet_Home_paragraph,
  .Training_paragraph {
    font-size: 25px;
  }
}

/* ------------------------Popular_Stores---------------------------- */
.Services_Stores {
  padding: 1% 5% 5% 5%;
}
@media (max-width: 765px) {
  .Services_Stores {
    padding: 6% 5% 5% 5%;
  }
}
.Popular_Stores {
  padding: 3% 5% 5% 5%;
}
.title button {
  width: 100%;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
}
.productbtn:hover {
  background-color: #e4e4e7;
}
.productbtn0 {
  background-color: #e4e4e7;
  height: 70px;
}
.productbtn1 {
  background-color: #f5d1f5;
  height: 70px;
}
.productbtn2 {
  background-color: #c2f7f2;
  height: 70px;
}
.productbtn3 {
  background-color: #ffe8cb;
  height: 70px;
}
.productbtn4 {
  background-color: #ebebeb;
  height: 70px;
}
@media (max-width: 575.98) {
  /* .row
    .row-cols-xs-1
    .row-cols-md-2
    .row-cols-lg-3
    .g-4
    .stores */
  .stores_conteiner {
    /* display: flex !important;
    flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
  }
}
@media (max-width: 400px) {
  .stores_conteiner {
    margin-left: 0%;
  }
}
/* ----------------------------Latest_Articles---------------------- */
.article_image {
  width: 380px;
  height: 200px;
}
.Article_title {
  width: 380px;
}
@media (max-width: 1250px) {
  .article_image {
    width: 300px;
  }
  .Article_title {
    width: 300px;
  }
}
@media (max-width: 980px) {
  .article_image {
    width: 380px;
  }
  .Article_title {
    width: 380px;
  }
}
@media (max-width: 980px) {
  .article_image {
    width: 300px;
  }
  .Article_title {
    width: 300px;
  }
}
@media (max-width: 690px) {
  .Articles_New_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .article_image {
    width: 380px;
  }
  .Article_title {
    width: 380px;
  }
}

@media (max-width: 400px) {
  .Articles_New_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .article_image {
    width: 300px;
  }
  .Article_title {
    width: 300px;
  }
}

/* -----------------------------Download App----------------------- */
.Download_App {
  display: flex;
  justify-content: space-around;
  padding: 5% 5%;
}
.Download_App_images {
  width: 50%;
}
.Download_App_images .image1 {
  position: relative;
  width: 37vw;
  height: 37vw;
  top: 10%;
  right: 10%;
}
.Download_App_images .image2 {
  position: absolute;
  left: 18%;
}
.Download_App_images .image3 {
  position: absolute;
  width: 25vw;
  left: 8%;
}
.Download_App_images .image4 {
  position: absolute;
  right: 10%;
}
.Download_App_images .image5 {
  position: absolute;
  width: 25vw;
  right: 24%;
}
.Download_App_text {
  width: 45%;
  margin-top: 5%;
}
.AppStore,
.PlayStore {
  width: 130px;
  height: 40px;
}
.Download_App_text h1 {
  font-size: 3.5vw;
  width: 70%;
}
@media screen and (max-width: 1210px) {
  .Download_App_images .image2 {
    left: 15%;
  }
}
@media screen and (max-width: 990px) {
  .Download_App_images .image1 {
    height: 350px;
    width: 350px;
  }
  .Download_App_images .image3 {
    height: 450px;
    width: 250px;
  }
  .Download_App_images .image2 {
    left: 13%;
  }

  .Download_App_images .image4 {
    right: 6%;
  }
  .Download_App_images .image5 {
    height: 450px;
    width: 235px;
  }
}

@media screen and (max-width: 870px) {
  .Download_App {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Download_App_images {
    width: 80%;
  }
  .Download_App_images .image1 {
    top: 15%;
    right: 0;
    left: 10%;
    height: 400px;
    width: 400px;
  }
  .Download_App_images .image3 {
    height: 450px;
    left: 30%;
  }
  .Download_App_images .image2 {
    left: 38%;
  }

  .Download_App_images .image4 {
    height: 450px;
    right: 1%;
  }
  .Download_App_images .image5 {
    height: 550px;
    width: 280px;
   
  }
  .Download_App_text {
    margin-top: 15%;
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .Download_App {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Download_App_images {
    width: 100%;
  }
  .Download_App_images .image1 {
    top: 15%;
    right: 0;
    left: 10%;
    height: 350px;
    width: 350px;
  }
  .Download_App_images .image3 {
    height: 450px;
    left: 20%;
  }
  .Download_App_images .image2 {
    left: 30%;
  }
  .Download_App_text {
    margin-top: 15%;
    margin-left: 8%;
    width: 100%;
  }
}

/* --------------------- */
.scrollable-container {
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollable-container::-webkit-scrollbar {
  width: 0.3em;
}
.scrollable-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
body {
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  width: 0.3em;
}
body::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.storebutton {
  min-width: max-content;
  background-color: #ebebeb;
  padding: 5px 10px 7px 10px;
}
.storebutton:hover {
  background-color: #e0d9fa;
}
.Availablebutton {
  min-width: max-content;
  background-color: #e0d9fa;
  padding: 5px 10px 7px 10px;
  font-family: black;
}
.foodbtn {
  background-color: #e0d9fa;
  width: 80%;
  height: 10%;
  right: 10%;
}
.foodimg:hover {
  border: 1px dotted #e0d9fa;
}
.foodimg:hover + .foodbtn {
  display: block;
}
.accordion-button:not(.collapsed) {
  background-color: white;
}
.accordion-button:focus {
  box-shadow: none;
}
@media (min-width: 1280px) {
  .stores > div {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 2001px) {
  .stores > div {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 2201px) {
  .stores > div {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

/* Loader */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 30%;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* -------------------------------Services------------------------ */
.serviceproduct {
  width: 70%;
}
.serviceslots {
  width: 20%;
}

@media screen and (max-width: 735px) {
  /* .serviceses {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center; 
    text-align: center;
  } */
  .services-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .serviceproduct {
    /* width: 285px; */
    margin-top: 2%;
    width: 100%;
    /* padding-left: 14%; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  .serviceslots {
    width: 70%;
  }
}
@media screen and (min-width: 736px) and (max-width: 820px) {
  .services-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .serviceproduct {
    width: 100%;
    margin-top: 2%;
  }
  .serviceslots {
    width: 40%;
  }
}
@media screen and (min-width: 821px) and (max-width: 990px) {
  .serviceproduct {
    width: 67%;
  }
  .serviceslots {
    padding-left: 7%;
    width: 24%;
  }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .serviceslots {
    padding-left: 5%;
    width: 24%;
  }
}
/* -------------------------------Products------------------------ */
.storeproduct {
  width: 70%;
}
.storecategory {
  width: 20%;
}
@media (max-width: 850px) {
  .storeproduct {
    width: 50%;
  }
  .storecategory {
    width: 40%;
  }
}
@media (max-width: 750px) {
  .flex_direction {
    display: flex;
    flex-direction: column-reverse;
  }
  .storeproduct {
    width: 80%;
  }
  .storecategory {
    width: 75%;
  }
}

/* ---------------------------Store product details------------- */
/* .storeproductdetailimg {
  padding-left: 200px;
}
@media (max-width: 1136px) {
  .storeproductdetailimg {
    padding-left: 100px;
  }
} */

/* --------------------NearMe ----------------- */

@media screen and (max-width: 762px) {
  .NearMeStores {
    display: flex;
    flex-direction: column-reverse;
  }
}

/* ----------------------------- */
.selected {
  background-color: black;
  color: #fff;
}

/* ----------------------Marker Style-------------------- */

.triangle {
  position: relative;
  margin: 3em;
  padding: 1em;
  box-sizing: border-box;
  background: #bada55;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
}
.triangle::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.5em;
  bottom: -2em;
  left: 50%;
  box-sizing: border-box;

  border: 1em solid black;
  border-color: transparent transparent #bada55 #bada55;

  transform-origin: 0 0;
  transform: rotate(-45deg);

  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
}

/* ----------------------------------------------- */
.cart-item-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: relative;
  right: 25%;
  bottom: 30%;
}
/* ------------------- */

.custom-modal .modal-content {
  background-color: #e0d9fa;
}

.pac-container {
  background-color: #fff;
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}
.modal {
  z-index: 2000;
}
.modal-backdrop {
  z-index: 1000;
}
