.detail {
    .data {
      margin-top: 10px;
      padding: 120px 0;
    //   background-image: url("../../Assets/pcircle.png"),
    //     url("../../Assets/ppaw.png"), url("../../Assets/circle.png"),
    //     url("../../Assets/pstar.png");
    background-image: url("../../images/pcircle.png"),
    url("../../images/ppaw.png"), url("../../images/circle.png"),
    url("../../images/pstar.png");
      min-height: 52vh;
      background-repeat: no-repeat;
      background-position: calc(7%) 5vh, calc(30%) 20vh, calc(100% - 7%) 35vh,
        calc(100% - 3%) 10vh;
      display: flex;
      flex-direction: column;
      .gramb {
        font-size: 45px;
        line-height: 40px;
        color: black;
        padding: 0 11%;
        // align-self: center;
      }
      .img {
        align-self: center;
        margin-top: 50px;
        width: 60%;
        height: 80vh;
        border-radius: 8px;
        @media (max-width: 767px) {
          height: 35vh;
        }
      }
      .blog {
        padding: 2% 11%;
        display: flex;
        flex-direction: column;
        .content {
          overflow: hidden;
          padding: 20px;
          list-style-type: none;
        }
        @media (max-width: 767px) {
          padding: 2% 8%;
        }
        .grama {
          padding: 20px 0px;
          font-size: 18px;
          text-align: justify;
          word-wrap: break-word;
          li {
            padding: 15px 0px;
            margin-left: 15px;
          }
          @media (max-width: 767px) {
            text-align: start;
          }
        }
        .img {
          width: 100%;
        }
        .stylish {
          background-color: #ebf2fe;
          box-shadow: 0px 4px 0px 0px #1d54c0 inset;
          border-radius: 8px;
          font-size: 22px;
          padding: 20px 30px;
          margin: 10px 0;
          word-wrap: break-word;
        }
      }
    }
  }