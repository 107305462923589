.page {
    .data {
      background-image: url("../../images/pcircle.png"),
        url("../../images/ppaw.png"), url("../../images/circle.png"),
        url("../../images/pstar.png");
      min-height: 52vh;
      background-repeat: no-repeat;
      background-position: calc(7%) 5vh, calc(45%) 25vh, calc(100% - 7%) 40vh,
        calc(100% - 3%) 10vh;
      padding: 80px 9%;
      // display: flex;
      .gramb {
        font-size: 45px;
        color: black;
        line-height: 45px;
        // align-self: center;
      }
      .blogs {
        margin: 10% 7%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        .grama {
          font-size: 36px;
          color: #232e52;
        }
        .blog {
          display: flex;
          padding: 0;
          margin: 0;
          gap: 30px;
          .bloginfo {
            display: flex;
            flex-direction: column;
            flex: 2.3;
            .top {
              display: flex;
              text-align: justify;
              gap: 10px;
              .grama {
                font-size: 15px;
                padding: 3px 10px;
                min-width: 120px;
                text-align: center;
                background-color: #ebf2fe;
                border-radius: 8px;
              }
              .date {
                background-color: white;
              }
            }
            .gramb {
              font-size: 28px;
              color: #232e52;
              line-height: 36px;
            }
            .grama {
              font-size: 18px;
              color: #232e52;
              word-wrap: break-word;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-align: justify;
              overflow: hidden;
            }
          }
          .imgwrap {
            flex: 0.8;
            overflow: hidden;
            width: 100%;
            min-height: 210px; /* Set the maximum height */
            border-radius: 8px;
            position: relative;
            .img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover; /* Maintain aspect ratio and cover entire container */
              border-radius: 8px;
            }
          }
        }
      }
      .btns {
        margin-top: auto;
        display: flex;
        gap: 10px;
        align-self: center;
        .btn {
          height: 40px;
          width: 40px;
          background-color: #000000;
          border: none;
          color: white;
          border-radius: 50%;
        }
      }
    }
  }